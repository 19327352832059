body {
  background-color: #f0f2f3;
}

.yield-body {
  min-height: 825px;
}

.header {
  border-bottom: 1px solid #c7c7c7;
  background-color: white;
}
.header .admin-menu .logo {
  width: 140px;
  margin-right: 10px;
}
.header .admin-menu .navbar-brand {
  font-weight: bold;
}

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 18px;
  background-color: white;
  margin-top: 30px;
}
.footer a {
  text-decoration: none;
  line-height: 27px;
}
.footer .list-menu .download-android-btn {
  height: 85px;
}
.footer .bottom-block {
  margin-top: 30px;
  color: #666666;
}
.footer .bottom-block .logo-footer {
  height: 56px;
}
.footer .bottom-block .term-privacy-block {
  text-align: left;
  padding-top: 15px;
}
.footer .bottom-block a {
  font-size: 16px;
  font-weight: 500;
}
.footer .bottom-block .copyright-block {
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  text-align: left;
  padding-top: 15px;
}
.footer .bottom-block .support-block {
  text-align: left;
  padding-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-device-width: 768px) {
  .yield-body {
    min-height: unset;
  }
}
body, html {
  height: 100%;
  font-family: Roboto, sans-serif;
}

/*---------------------------------------------*/
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #57b846;
}

/*---------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  margin: 0px;
}

ul, li {
  margin: 0px;
  list-style-type: none;
}

.nav-link {
  font-size: 18px;
}

/*---------------------------------------------*/
input.form-control, select.form-select, textarea.form-control {
  outline: none;
  border-radius: 5px;
  height: 50px;
}

select.form-select.reset-form-select {
  height: 39px;
}

textarea.form-control:focus, select.form-select:focus, input.form-control:focus {
  border-color: black;
  outline: none;
  box-shadow: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input:-moz-placeholder {
  color: #999999;
}

input::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}

textarea:-moz-placeholder {
  color: #999999;
}

textarea::-moz-placeholder {
  color: #999999;
}

textarea:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

.red-color {
  color: red;
}

.green-color {
  color: green;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.custom-navbar {
  padding: 5px 0px;
}

#partner-block .left-content {
  padding-top: 50px;
  padding-bottom: 60px;
}
#partner-block .left-content .line-1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}
#partner-block .left-content .line-2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  padding-top: 15px;
}
#partner-block .left-content .line-3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-top: 35px;
}
#partner-block .left-content .register-btn {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #000000;
}
#partner-block .left-content .register-btn, #partner-block .left-content .login-btn {
  color: #000000;
  border-radius: 5px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
#partner-block .left-content .register-btn .icon-regis, #partner-block .left-content .login-btn .icon-regis {
  width: 48px;
  height: 48px;
  background: #39ADAA;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 20px;
}
#partner-block .left-content .register-btn .next-btn, #partner-block .left-content .login-btn .next-btn {
  background-color: #dbdada;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  right: 20px;
}
#partner-block .left-content .register-btn .next-btn img, #partner-block .left-content .login-btn .next-btn img {
  width: 70%;
  margin: auto;
}
#partner-block .left-content .register-btn:hover, #partner-block .left-content .login-btn:hover {
  border: 2px solid #000000;
}
#partner-block .left-content .register-btn:hover .next-btn, #partner-block .left-content .login-btn:hover .next-btn {
  background-color: rgba(57, 173, 170, 0.25);
}
#partner-block .right-content {
  background-color: #39ADAA;
  min-height: 800px;
  position: relative;
  overflow: hidden;
}
#partner-block .right-content img.cover-dashboard {
  width: 700px;
  position: absolute;
  right: -150px;
  top: calc(50% - 250px);
}

@media only screen and (max-device-width: 768px) {
  #partner-block .right-content {
    min-height: 400px;
  }
}
#create-account-block .left-content {
  padding-top: 50px;
  padding-bottom: 100px;
}
#create-account-block .left-content .line-1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
}
#create-account-block .left-content .form-group {
  margin-top: 15px;
}
#create-account-block .left-content .read-term-block {
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
}
#create-account-block .left-content .read-term-block a {
  font-size: 18px;
  color: #000;
}
#create-account-block .left-content .login-block {
  font-size: 18px;
  color: #686868;
  margin-top: 20px;
}
#create-account-block .left-content .login-block a {
  font-size: 18px;
  color: black;
  font-weight: 400;
  text-decoration: none;
}
#create-account-block .left-content .register-btn {
  float: right;
  background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  padding: 5px 15px;
  margin-top: 20px;
  font-weight: 700;
}
#create-account-block .left-content .error-text {
  color: red;
}
#create-account-block .right-content {
  background-color: #39ADAA;
  min-height: 800px;
  position: relative;
  overflow: hidden;
}
#create-account-block .right-content img.cover-dashboard {
  width: 700px;
  position: absolute;
  right: -150px;
  top: calc(50% - 250px);
}

@media only screen and (max-device-width: 768px) {
  #create-account-block .right-content {
    min-height: 400px;
  }
}
#login-block .left-content {
  padding-top: 50px;
  padding-bottom: 100px;
}
#login-block .left-content .line-1 {
  font-size: 32px;
  font-weight: bold;
}
#login-block .left-content .line-2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
}
#login-block .left-content .form-group {
  margin-top: 15px;
}
#login-block .left-content .register-block {
  font-size: 18px;
  color: #686868;
  margin-top: 20px;
}
#login-block .left-content .register-block a {
  font-size: 18px;
  color: black;
  font-weight: 400;
  text-decoration: none;
}
#login-block .left-content .login-btn {
  float: right;
  background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  padding: 5px 15px;
  margin-top: 20px;
  font-weight: 700;
}
#login-block .left-content .error-text {
  color: red;
}
#login-block .left-content .link_to_forgot {
  float: right;
  font-size: 16px;
  color: black;
  font-weight: 400;
  text-decoration: none;
}
#login-block .right-content {
  background-color: #39ADAA;
  min-height: 800px;
  position: relative;
  overflow: hidden;
}
#login-block .right-content img.cover-dashboard {
  width: 700px;
  position: absolute;
  right: -150px;
  top: calc(50% - 250px);
}

@media only screen and (max-device-width: 768px) {
  #login-block .right-content {
    min-height: 400px;
  }
}
#email-code-block .left-content {
  padding-top: 50px;
  padding-bottom: 100px;
}
#email-code-block .left-content .line-1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: black;
}
#email-code-block .left-content .code-group {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
}
#email-code-block .left-content .code-group input {
  width: 40px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0px 5px;
  text-align: center;
}
#email-code-block .left-content .code-group input:focus {
  border: 2px solid #000000;
}
#email-code-block .left-content .code-group .success-text {
  color: #39ADAA;
}
#email-code-block .left-content .code-group .error-text {
  color: rgb(173, 22, 52);
}
#email-code-block .left-content .verify-btn {
  background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  padding: 5px 15px;
  margin-left: 20px;
  font-weight: 700;
}
#email-code-block .left-content .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
#email-code-block .left-content .button-group a {
  font-size: 18px;
  text-decoration: none;
  color: #000000;
}
#email-code-block .right-content {
  background-color: #39ADAA;
  min-height: 800px;
  position: relative;
  overflow: hidden;
}
#email-code-block .right-content img.cover-dashboard {
  width: 700px;
  position: absolute;
  right: -150px;
  top: calc(50% - 250px);
}

#register-steps .left-content {
  padding-top: 50px;
  padding-bottom: 15px;
}
#register-steps .left-content .line-1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}
#register-steps .left-content .group-text {
  margin-top: 30px;
  border-left: 5px solid #39ADAA;
  padding-left: 15px;
  width: 80%;
}
#register-steps .left-content .group-text p {
  font-size: 16px;
  padding-bottom: 10px;
}
#register-steps .right-content {
  background-color: #39ADAA;
  min-height: 615px;
  position: relative;
}
#register-steps .right-content img.cover-dashboard {
  width: calc(100% + 60px);
  position: absolute;
  right: 0;
  bottom: 0;
}
#register-steps .step-status-block {
  padding: 50px 0px;
}
#register-steps .step-status-block .step-cover {
  position: relative;
  width: 100%;
  height: 30px;
  background: #D9D9D9;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}
#register-steps .step-status-block .step-partial {
  background: #39ADAA;
  border-radius: 30px;
  width: 50%;
  height: 24px;
  position: absolute;
  left: 3px;
  top: 3px;
}
#register-steps .step-status-block .step-full {
  background: #39ADAA;
  border-radius: 30px;
  width: calc(100% - 5px);
  height: 24px;
  position: absolute;
  left: 3px;
  top: 3px;
}
#register-steps .content {
  padding: 100px;
  background: #D9D9D9;
  border-radius: 5px;
}
#register-steps .content p {
  padding: 15px 0px;
  color: black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
#register-steps .content .next-btn {
  background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  padding: 7px 25px;
  margin-top: 20px;
  margin-right: 10px;
}
#register-steps .content .form-group {
  margin-top: 15px;
}
#register-steps .content span.validate {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #686868;
}
#register-steps .content .image-select {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
}
#register-steps .content .container {
  border: 1px solid hsl(0deg, 1%, 28%);
  border-radius: 5px;
  margin-bottom: 15px;
  margin-left: 30px;
  padding: 6px 30px;
  display: flex;
  justify-content: space-between;
}
#register-steps .content .container .btn-upload-image {
  position: relative;
  display: inline-block;
}
#register-steps .content .container .custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
#register-steps .content .container .custom-file-label {
  display: block;
  padding-top: 7px;
  padding-left: 9px;
  background-color: none;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
#register-steps .content .container .btn-remove {
  display: block;
  color: #FF0000;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
#register-steps .content #national_id_select {
  display: none;
}
#register-steps .content #passport_select {
  display: none;
}
#register-steps .content .error-text {
  color: red;
}

@media only screen and (max-device-width: 768px) {
  #register-steps .content {
    padding: 15px;
  }
  #register-steps .right-content {
    min-height: 300px;
  }
}
.top-block {
  margin-top: 30px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 5px;
}
.top-block .text-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}
.top-block .text-description {
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding-left: 10px;
  border-left: 5px solid #39ADAA;
}

.bottom-block {
  position: relative;
}
.bottom-block .list-label {
  margin-top: 30px;
  background-color: white;
  padding: 15px;
  width: 300px;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
}
.bottom-block .list-label .ri-list-ordered {
  color: #39ADAA;
  margin-right: 10px;
  font-size: 24px;
}
.bottom-block .voucher-list {
  overflow-x: scroll;
  background-color: white;
  padding: 15px;
}
.bottom-block .buy-back-btn {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 7px 25px;
  position: absolute;
  right: 0px;
  top: 0px;
  color: black;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}
.bottom-block .buy-back-btn i {
  margin-right: 10px;
}

@media only screen and (max-device-width: 768px) {
  .bottom-block .list-label {
    width: 100%;
  }
  .bottom-block .buy-back-btn {
    top: 10px;
  }
  .bottom-block .buy-back-btn {
    padding: 7px;
  }
}
#forgot-password-block .left-content {
  padding-top: 50px;
  padding-bottom: 100px;
}
#forgot-password-block .left-content .line-1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}
#forgot-password-block .left-content .line-2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  padding-top: 15px;
}
#forgot-password-block .left-content .form-group {
  margin-top: 60px;
}
#forgot-password-block .left-content .read-term-block {
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
}
#forgot-password-block .left-content .read-term-block a {
  font-size: 18px;
}
#forgot-password-block .left-content .login-block {
  font-size: 18px;
  color: #686868;
  margin-top: 20px;
}
#forgot-password-block .left-content .login-block a {
  font-size: 18px;
  color: black;
  font-weight: 400;
  text-decoration: none;
}
#forgot-password-block .left-content .submit-btn {
  float: right;
  background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  padding: 5px 15px;
  margin-top: 20px;
  font-weight: 700;
}
#forgot-password-block .left-content .error-text {
  color: red;
}
#forgot-password-block .right-content {
  background-color: #39ADAA;
  min-height: 800px;
  position: relative;
  overflow: hidden;
}
#forgot-password-block .right-content img.cover-dashboard {
  width: 700px;
  position: absolute;
  right: -150px;
  top: calc(50% - 250px);
}

@media only screen and (max-device-width: 768px) {
  #forgot-password-block .right-content {
    min-height: 400px;
  }
}
#cart .choose-plan {
  margin-bottom: 20px;
}
#cart .summary-text {
  font-size: 12px;
}
#cart .left-column, #cart .right-column {
  background-color: white;
}
#cart .right-column {
  padding-top: 30px;
  border-left: #39ADAA 7px solid;
}

#top-up .qr-code-block img {
  width: 250px;
}

.pagination a, .pagination span.current, .pagination span.gap {
  float: left;
  padding: 0 14px;
  line-height: 38px;
  text-decoration: none;
  background-color: white;
  border: 1px solid #DDD;
  border-left-width: 0;
}

.pagination {
  border-left: 1px solid #ddd;
}
.pagination .first {
  padding: 0;
  float: none;
  border: none;
}
.pagination .prev {
  padding: 0;
  float: none;
  border: none;
}
.pagination .page {
  padding: 0;
  float: none;
  border: none;
}
.pagination .next {
  padding: 0;
  float: none;
  border: none;
}
.pagination .last {
  padding: 0;
  float: none;
  border: none;
}

.dashboard-block {
  margin-top: 30px;
}
.dashboard-block .admin-list {
  background-color: white;
  padding: 15px;
}
.dashboard-block .admin-list .image-select {
  width: 100%;
}
.dashboard-block .admin-list .each-block {
  border: 1px solid white;
  background-color: #39ADAA;
  padding: 15px 5px;
  font-weight: bold;
}
.dashboard-block .admin-list .each-block .title {
  color: #dedbdb;
  font-size: 20px;
}
.dashboard-block .admin-list .each-block .number {
  font-size: 30px;
  color: white;
}
.dashboard-block .table-scroll {
  overflow-x: scroll;
}