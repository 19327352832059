#cart {
  .choose-plan {
    margin-bottom: 20px;
  }
  .summary-text {
    font-size: 12px;
  }
  .left-column, .right-column {
    background-color: white;
  }
  .left-column {

  }
  .right-column {
    padding-top: 30px;
    border-left: #39ADAA 7px solid;
  }
}
