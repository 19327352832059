.pagination a, .pagination span.current, .pagination span.gap {
  float: left;
  padding: 0 14px;
  line-height: 38px;
  text-decoration: none;
  background-color: white;
  border: 1px solid #DDD;
  border-left-width: 0;
}

.pagination {
  border-left: 1px solid #ddd;
  .first{
    padding : 0;
    float: none;
    border: none;
  }
  .prev {
    padding : 0;
    float: none;
    border: none;
 }
  .page{
    padding : 0;
    float: none;
    border: none;
  }
  .next{
    padding : 0;
    float: none;
    border: none;
  }
  .last{
    padding : 0;
    float: none;
    border: none;
  }
}
