body {
  background-color: #f0f2f3;
}

.yield-body {
  min-height: 825px;
}

.header {
  border-bottom: 1px solid #c7c7c7;
  background-color: white;
  .admin-menu {
    .logo {
      width: 140px;
      margin-right: 10px;
    }
    .navbar-brand {
      font-weight: bold;
    }
  }
}

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 18px;
  background-color: white;
  margin-top: 30px;
  a {
    text-decoration: none;
    line-height: 27px;
  }

  .list-menu {
    .download-android-btn {
      height: 85px;
    }
  }

  .bottom-block {
    margin-top: 30px;
    color: #666666;
    .logo-footer {
      height: 56px;
    }
    .term-privacy-block {
      text-align: left;
      padding-top: 15px;
    }
    a {
      font-size: 16px;
      font-weight: 500;
    }
    .copyright-block {
      font-weight: 400;
      font-size: 16px;
      line-height: 38px;
      text-align: left;
      padding-top: 15px;
    }
    .support-block {
      text-align: left;
      padding-top: 15px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .yield-body {
    min-height: unset;
  }
}
