body, html {
  height: 100%;
  font-family: Roboto, sans-serif;
}

/*---------------------------------------------*/
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #57b846;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
  margin: 0px;
}

ul, li {
  margin: 0px;
  list-style-type: none;
}

.nav-link {
  font-size: 18px;
}

/*---------------------------------------------*/
input.form-control, select.form-select, textarea.form-control {
  outline: none;
  border-radius: 5px;
  height: 50px;
}

select.form-select.reset-form-select {
  height: 39px;
}

textarea.form-control:focus, select.form-select:focus, input.form-control:focus {
  border-color: black;
  outline: none;
  box-shadow: none;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

.red-color {
  color: red;
}
.green-color {
  color: green;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.custom-navbar {
  padding: 5px 0px;
}
