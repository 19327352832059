#email-code-block {
  .left-content {
    padding-top: 50px;
    padding-bottom: 100px;

    .line-1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: black;
    }

    .code-group {
      display: flex;
      justify-content: center;
      margin: 30px 0px;
      input {
        width: 40px;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin: 0px 5px;
        text-align: center;
        &:focus {
          border: 2px solid #000000;
        }
      }
      .success-text{
        color: #39ADAA;
      }

      .error-text{
        color: rgb(173, 22, 52);
      }
    }

    .verify-btn {
      background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
      border-radius: 5px;
      font-size: 18px;
      color: white;
      padding: 5px 15px;
      margin-left: 20px;
      font-weight: 700;
    }

    .button-group {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        font-size: 18px;
        text-decoration: none;
        color: #000000;
      }
    }
  }
  .right-content {
    background-color: #39ADAA;
    min-height: 800px;
    position: relative;
    overflow: hidden;

    img.cover-dashboard {
      width: 700px;
      position: absolute;
      right: -150px;
      top: calc(50% - 250px);
    }
  }
}
