.dashboard-block {
  margin-top: 30px;
  .admin-list {
    background-color: white;
    padding: 15px;

    .image-select {
      width: 100%;
    }

    .each-block {
      border: 1px solid white;
      background-color: #39ADAA;
      padding: 15px 5px;
      font-weight: bold;

      .title {
        color: #dedbdb;
        font-size: 20px;
      }
      .number {
        font-size: 30px;
        color: white;
      }
    }
  }

  .table-scroll {
    overflow-x: scroll;
  }
}
