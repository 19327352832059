#partner-block {
  .left-content {
    padding-top: 50px;
    padding-bottom: 60px;

    .line-1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }
    .line-2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      padding-top: 15px;
    }
    .line-3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      padding-top: 35px;
    }


    .register-btn {
      margin-top: 30px;
      margin-bottom: 30px;
      color: #000000;
    }

    .register-btn, .login-btn {
      color: #000000;
      border-radius: 5px;
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      text-decoration: none;
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.25);


      .icon-regis {
        width: 48px;
        height: 48px;
        background: #39ADAA;
        border-radius: 100%;
        margin-left: 20px;
        margin-right: 20px;
      }

      .next-btn {
        background-color: #dbdada;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        display: flex;
        position: absolute;
        right: 20px;
        img {
          width: 70%;
          margin: auto;
        }
      }

      &:hover {
        border: 2px solid #000000;

        .next-btn {
          background-color: rgba(57, 173, 170, 0.25);
        }
      }
    }
  }
  .right-content {
    background-color: #39ADAA;
    min-height: 800px;
    position: relative;
    overflow: hidden;

    img.cover-dashboard {
      width: 700px;
      position: absolute;
      right: -150px;
      top: calc(50% - 250px);
    }
  }
}

@media only screen and (max-device-width: 768px) {
  #partner-block {
    .right-content {
      min-height: 400px;
    }
  }
}


