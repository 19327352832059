.top-block {
  margin-top: 30px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 5px;

  .text-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }
  .text-description {
    margin-top: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    padding-left: 10px;
    border-left: 5px solid #39ADAA;
  }
}


.bottom-block {
  position: relative;

  .list-label {
    margin-top: 30px;
    background-color: white;
    padding: 15px;
    width: 300px;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;

    .ri-list-ordered {
      color: #39ADAA;
      margin-right: 10px;
      font-size: 24px;
    }
  }
  .voucher-list {
    overflow-x: scroll;
    background-color: white;
    padding: 15px;
  }
  .buy-back-btn {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 7px 25px;
    position: absolute;
    right: 0px;
    top: 0px;
    color: black;
    font-size: 18px;
    text-decoration: none;
    display: flex;
    i {
      margin-right: 10px;
    }
  }
}


@media only screen and (max-device-width: 768px) {
  .bottom-block {
    .list-label {
      width: 100%;
    }
    .buy-back-btn {
      top: 10px;
    }
    .buy-back-btn {
      padding: 7px;
    }
  }
}

