#register-steps {
  .left-content {
    padding-top: 50px;
    padding-bottom: 15px;

    .line-1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }

    .group-text {
      margin-top: 30px;
      border-left: 5px solid #39ADAA;
      padding-left: 15px;
      width: 80%;
      p {
        font-size: 16px;
        padding-bottom: 10px;
      }
    }
  }
  .right-content {
    background-color: #39ADAA;
    min-height: 615px;
    position: relative;

    img.cover-dashboard {
      width: calc(100% + 60px);
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .step-status-block {
    padding: 50px 0px;
    .step-cover {
      position: relative;
      width: 100%;
      height: 30px;
      background: #D9D9D9;
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
    }
    .step-partial {
      background: #39ADAA;
      border-radius: 30px;
      width: 50%;
      height: 24px;
      position: absolute;
      left: 3px;
      top: 3px;
    }
    .step-full {
      background: #39ADAA;
      border-radius: 30px;
      width: calc(100% - 5px);
      height: 24px;
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }

  .content {
    padding: 100px;
    background: #D9D9D9;
    border-radius: 5px;

    p {
      padding: 15px 0px;
      color: black;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .next-btn {
      background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
      border-radius: 5px;
      font-size: 18px;
      color: white;
      padding: 7px 25px;
      margin-top: 20px;
      margin-right: 10px;
    }

    .form-group {
      margin-top: 15px;
    }

    span.validate {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #686868;
    }

    .image-select{
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      // height: 85%;
    }

    .container{
      border: 1px solid hsl(0, 1%, 28%);
      border-radius: 5px;
      margin-bottom: 15px;
      margin-left: 30px;
      padding: 6px 30px;
      display: flex;
      justify-content: space-between;

      .btn-upload-image {
        position: relative;
        display: inline-block;
      }

      .custom-file-input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
      }

      .custom-file-label {
        display: block;
        padding-top: 7px;
        padding-left: 9px;
        background-color: none;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }

      .btn-remove{
        display: block;
        color: #FF0000;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }
    }

    #national_id_select{
      display: none;
    }

    #passport_select{
      display: none;
    }

    .error-text {
      color: red;
    }
  }

}

@media only screen and (max-device-width: 768px) {
  #register-steps {
    .content {
      padding: 15px;
    }
    .right-content {
      min-height: 300px;
    }
  }
}
