#forgot-password-block {
  .left-content {
    padding-top: 50px;
    padding-bottom: 100px;

    .line-1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }

    .line-2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      padding-top: 15px;
    }

    .form-group {
      margin-top: 60px;
    }

    .read-term-block {
      font-size: 18px;
      line-height: 27px;
      margin-top: 20px;
      a {
        font-size: 18px;
      }


    }

    .login-block {
      font-size: 18px;
      color: #686868;
      margin-top: 20px;

      a {
        font-size: 18px;
        color: black;
        font-weight: 400;
        text-decoration: none;
      }
    }

    .submit-btn {
      float: right;
      background: linear-gradient(0deg, #39ADAA, #39ADAA), #39ADAA;
      border-radius: 5px;
      font-size: 18px;
      color: white;
      padding: 5px 15px;
      margin-top: 20px;
      font-weight: 700;
    }

    .error-text {
      color: red;
    }
  }
  .right-content {
    background-color: #39ADAA;
    min-height: 800px;
    position: relative;
    overflow: hidden;

    img.cover-dashboard {
      width: 700px;
      position: absolute;
      right: -150px;
      top: calc(50% - 250px);
    }
  }
}

@media only screen and (max-device-width: 768px) {
  #forgot-password-block {
    .right-content {
      min-height: 400px;
    }
  }
}

